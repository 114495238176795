.auth {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #ff0000;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
}

.pw{
  cursor: pointer;
}

.auth h1 {
  font-size: 1.3em;
  text-align: center;
  color: white;
}

.auth h3 {
  font-size: 1em;
  text-align: center;
  color: white;
  font-weight:normal;
}

.auth p {
  text-align: left;
  color: white;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: #ffffff;
  color: #ff0000;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;  
}

.error
{
  animation: shake 0.3s ease-in-out 0s 2;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}


.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: black;
  background-color: white;
  border: 1px solid white;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #ffffff;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: #ffffff;
}