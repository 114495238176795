.overviewContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
  margin-left: 5%; /* Fügt Platz auf der linken Seite hinzu */
  margin-right: 5%; /* Fügt Platz auf der rechten Seite hinzu */
}


.box {
  flex: 1 0 calc(45% - 32px); /* 45% - gap * 2 */
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); /* Fügt eine glatte Übergangsanimation hinzu */
  max-height: 300px;
  overflow: auto;
}

.box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.10), 0 3px 4px rgba(0, 0, 0, 0.06);
  transform: translateY(-0.5px); /* Verschiebt die Box etwas weniger nach oben */
}



h4 {
  margin: 0;
  margin-bottom: 5px;
  margin-top: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 4px;
}


.lastOneDay {
  color: green;
}

.lastSevenDays {
  color: limegreen;
}

.lastThirtyDays {
  color: lightgreen;
}

.legend {
  margin-top: 10px;
  font-size: 0.7rem;
}


.tableNoBorder {
  border-collapse: collapse;
  width: 70%; /* Verändern Sie diesen Wert, um die Tabellenbreite zu steuern */
  margin: 0 auto; /* Zentriert die Tabelle innerhalb der Box */
  max-width: 95%;

}

.tableNoBorder td {
  padding: 8px;
  text-align: left;
  border-top: 1px solid #f2f2f2;
}

.tableNoBorder tr:nth-child(even) {
  background-color: #fbfbfb;
}

.tableNoBorder tr:hover {
  background-color: #e0e0e0; /* Ändern Sie die Farbe nach Ihren Wünschen */
}

.tableNoBorder th:nth-child(1),
.tableNoBorder td:nth-child(1) {
  width: 70%;
}

.tableNoBorder th:nth-child(2),
.tableNoBorder td:nth-child(2) {
  width: 40%;
}
