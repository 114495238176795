.c-Downloads {
  .dls-wrapper {
    padding-top: 30px;
  }
}

.c-CampaignTable {
  margin-bottom: 35px;

  .table-title {}

  table {
    color: $color-table-font;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

    tr {
      &.tablehead {
        background: #F9FAFB;
        border-bottom: 1px solid #E5E7EB;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: .5px;
        color: #3c3c3c;
      }

      &:nth-of-type(odd) {
        background: #F9FAFB;
      }

      display: flex;
      background: white;
    }

    td {
      flex: 0 0 25%;
      padding: 15px 20px;

      &:nth-of-type(1) {
        flex-grow: 1;
      }

      &:nth-of-type(2) {
        max-width: 304px;
      }

      &:nth-of-type(3) {
        max-width: 200px;
      }

      &:nth-of-type(4) {
        max-width: 200px;

        a {
          position: relative;
          font-weight: 500;

          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background: red;
          }
        }
      }
    }
  }
}

/* .item must be first */
.item {
  align-items: center;
  color: $color-table-font;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.disabled-page {
  color: #808e9b;
}

.activePage {
  text-decoration: underline;
  font-weight: 1000;
  border: solid 1px $color-table-font;
  border-radius: 40px;
  color: $color-table-font;
}

.break-me {}



.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  color: $color-dark-gray;
  width: 100%;
}

.pagination-page {
  font-weight: 700;
}

.next {
  font-size: 14px;
  height: 60px;
  position: absolute;
  right: 0;
  width: 150px;
}

.previous {
  font-size: 14px;
  height: 60px;
  left: 0;
  position: absolute;
  width: 150px;
}

/* Container for the BLZ filter input */
.blz-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

/* Label for the BLZ filter input */
.blz-filter label {
  margin-right: 8px;
  font-size: 16px;
}

/* BLZ filter input */
.blz-filter input {
  width: 200px;
  padding: 6px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

/* Focus state for the BLZ filter input */
.blz-filter input:focus {
  border-color: #007bff;
}