.c-Header{
  background: red;
  height: 64px;
  display: flex;
  align-items: center;
  nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    a,.nav-button{
      color: white;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      border-radius: 5px;
      background: rgba(0,0,0,0);
      padding: 0px 12px;
      margin: 0px 8px;
      transition: all .2s ease-in-out;
      &:hover{
        cursor: pointer;
        background: rgba(0,0,0,.25);
      }
      &.active{
        background: rgba(0,0,0,.5);
      }
      &:nth-of-type(1){
        margin-left: 0;
      }
      &:nth-last-of-type(1){
        margin-right: 0;
      }
    }
  }
}