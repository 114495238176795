.c-Campaigns {
  .cardwrapper {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    margin: 20px -15px;
  }
}

.c-CampaignCard {
  display: flex;
  flex: 0 0 calc(33.33% - 70px); // 2* margin + 2* padding
  flex-direction: column;
  margin: 15px;
  background: white;
  padding: 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  transition: all .2s ease-in-out;

  .button {
    background: $color-dark-gray;
    color: white;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    font-weight: 600;
    border-radius: 5px;
    transition: all .2s ease-in-out;
  }

  &:hover {
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);

    .button {
      background: red;
    }
  }
}

.c-CampaignCard:nth-child(1) {
  margin-right: 100%;
}

.c-stepsEmptyNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin: 30px 0;
}

.c-stepsNavigation {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  border-radius: 5px;
  border: 1px solid #E5E5E5;
  margin: 30px 0;
  gap: 10px;
  max-height: fit-content;
  /* Sorgt für einen Abstand zwischen den Schritten */

  .step {
    position: relative;
    /* Setzt den Bezugspunkt für absolute positionierte Kinder */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Passt die Ausrichtung an, um Platz für den Kreis zu lassen */
    text-align: center;
    white-space: normal;
    width: 120px;
    /* Feste Breite */
    height: 100px;
    /* Feste Höhe */
    margin: 5px;
    padding-top: 60px;
    /* Platz oben für den Kreis */
    box-sizing: border-box;

    /* Zeigt Auslassungspunkte für abgeschnittenen Text */
    &:before {
      content: "01";
      position: absolute;
      top: 10px;
      /* Abstand von oben */
      left: 50%;
      /* Zentriert den Kreis horizontal */
      transform: translateX(-50%);
      /* Korrigiert die Zentrierung basierend auf der Kreisbreite */

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #bfbfbf;
      width: 40px;
      height: 40px;
      font-weight: 700;
      transition: all .2s ease-in-out;
    }

    &:nth-of-type(2) {
      &:before {
        content: "02";
      }
    }

    &:nth-of-type(3) {
      &:before {
        content: "03";
      }
    }

    &:nth-of-type(4) {
      &:before {
        content: "04";
      }
    }

    &:nth-of-type(5) {
      &:before {
        content: "05";
      }
    }

    &:nth-of-type(6) {
      &:before {
        content: "06";
      }
    }

    &:nth-of-type(7) {
      &:before {
        content: "07";
      }
    }

    &:nth-of-type(8) {
      &:before {
        content: "08";
      }
    }

    &:nth-of-type(9) {
      &:before {
        content: "09";
      }
    }

    &:nth-of-type(10) {
      &:before {
        content: "10";
      }
    }

    &:nth-of-type(11) {
      &:before {
        content: "11";
      }
    }

    &:nth-of-type(12) {
      &:before {
        content: "12";
      }
    }

    &.active {
      color: red;

      &:before {
        background: white;
        color: red;
        border-color: red;
        //content: "\2713";
      }
    }

    &.finished {
      color: black;

      &:before {
        background: red;
        color: white;
        border-color: red;
        content: "\2713";
      }
    }
  }
}

.c-Uebersicht {
  display: flex;

  .big-image {
    flex: 1 0 60%;
    margin-right: 50px;

    img {
      border-radius: 5px;
    }
  }

}

.c-Logoauswahl {
  display: flex;

  h4 {
    margin-bottom: 15px;
  }

  textarea {
    padding: 10px;
    border: 0;
    outline: none;
    margin-bottom: 15px;
    resize: none;
  }

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  ul ul {
    list-style-type: circle;
    padding-left: 20px;
  }

  .textarea-invalid {
    border: 1px solid red;
  }

  .big-image {
    img {
      border-radius: 5px;
    }
  }
}

.c-Vorteil {
  h4 {
    margin-bottom: 30px;
  }

  .radio-wrapper {
    border: 1px solid $color-border-gray;
    border-top: none;
    padding: 15px 10px;

    &:nth-of-type(1) {
      border-top: 1px solid $color-border-gray;
    }

    &.selected {
      border: 1px solid red;
    }
  }
}

.c-Datenschutz {
  .datenschutz {
    padding: 30px 30px;
    background: white;
  }
}


.c-Context {
  display: flex;

  .contextImage-wrapper {
    display: flex;

    .contextImageCard {
      margin-right: 20px;
      margin-bottom: 14px;
      display: flex;
      flex-direction: column;

      img {
        margin-bottom: 5px;
        width: 180px;
        height: 180px;
        object-fit: cover;
        object-position: center center;
        border-radius: 5px;
        border: 4px solid $color-bg-gray;
        transition: all .2s ease-in-out;

        &.red {
          border: 4px solid red;
        }

        &.white {
          border: 4px solid rgb(255, 255, 255);
        }

        &:hover {
          border: 4px solid red;
          cursor: pointer;
        }

      }
    }

    .contextImageCard-small {
      margin-right: 20px;
      margin-bottom: 14px;
      display: flex;
      flex-direction: column;

      img {
        background-color: red;
        margin-bottom: 5px;
        width: 156px;
        height: 140px;
        object-fit: cover;
        object-position: center center;
        border-radius: 1px;
        border: 4px solid $color-bg-gray;
        transition: all .2s ease-in-out;

        &.red {
          border: 4px solid red;
        }

        &.white {
          border: 4px solid rgb(255, 255, 255);
        }

        &.grey {
          border: 4px solid #999999;
        }

        &:hover {
          border: 4px solid #999999;
          cursor: pointer;
        }

      }
    }
  }
}


.h-line {
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
}

.c-KampagnenBeschreibung {
  //border-bottom: 1px solid #E5E5E5;
  flex: 0 0 30%;
  margin-left: 30px;
  padding-right: 40px;

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  ul ul {
    list-style-type: circle;

    padding-left: 20px;
  }
}

.c-KampagnenUntereBeschreibung {
  float: both;
}

.c-ButtonBarModal {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .e-button {
    margin: 0 12px;
  }
}

.c-ButtonBar {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 30px;
  border-top: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;

  .e-button {
    margin: 0 12px;
  }
}

.e-textarea {
  width: 100%
}

.e-button {
  height: 42px;
  color: white;
  background: red;
  padding: 0px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all .2s ease-in-out;

  //padding-top: 20px;
  &:hover {
    background: $color-red-dark;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.2);
  }

  &.gray {
    background: $color-dark-gray;

    &:hover {
      background: black;
    }
  }
}

.Step {
  display: flex;

  .content {
    flex-grow: 1;
  }

  .content-sidebar {
    max-width: 300px;
    flex: 1 0 20%;
    margin-left: 50px;
  }

  .content-sidebar-large {
    max-width: 500px;
    flex: 1 0 50%;
    margin-left: 50px;
  }

  ul.main {
    list-style-type: disc;
    padding-left: 30px;
  }

  ul.sub {
    list-style-type: circle;
    padding-left: 30px;
  }

  ul.main>li {
    margin-bottom: 10px;
    /* Fügt einen Abstand zwischen den Hauptpunkten hinzu */
  }

  .einruecken {
    padding-left: 30px;
  }

}