/* src/components/AdminNavbar/AdminNavbar.css */

.c-AdminNavbar {
    background: white;
    height: 64px;
    display: flex;
    align-items: center;
  }
  
  .c-AdminNavbar .container ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
  }
  
  .c-AdminNavbar .container ul li a {
    color: black;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0);
    padding: 0px 12px;
    margin: 0px 8px;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  
  .c-AdminNavbar .container ul li a:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
  }
  
  .c-AdminNavbar .container ul li a.active {
    background: rgba(0, 0, 0, 0.2);
  }
  