body {
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

.campaign-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.campaign-dropdown-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.campaign-dropdown-container select {
  font-size: 1.2rem;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}

.selected-campaign-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.campaign-title {
  font-size: 2rem;
  margin: 0;
  color: #333;
}

table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
}

th, td {
  text-align: left;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-size: 1.2rem;
  color: #333;
}

tbody tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

/* Add a subtle transition on hover */
tbody tr, th {
  transition: background-color 0.3s;
}

.blz-filter-container,
.name-filter-container {
  margin-top: 5px;
}

.blz-filter-input,
.name-filter-input {
  font-size: 1rem;
  padding: 0.25rem;
  width: 100%;
  box-sizing: border-box;
}

.new-blz-input,
.new-name-input {
  font-size: 1rem;
  padding: 0.25rem;
  width: 100%;
  box-sizing: border-box;
}

.new-blz-button-container {
  display: inline-block;
  padding: 6px 12px;
  background-color: #4CAF50;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  
  transition: background-color 0.3s;
}

.new-blz-button-container:hover {
  background-color: #45a049;
}

.new-blz-button-container.error {
  background-color: #f44336;
}

.new-blz-button-container.error:hover {
  background-color: #d32f2f;
}


.delete-blz-button-container {
  display: inline-block;
  padding: 6px 12px;
  background-color: #f44336;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}

.delete-blz-button-container:hover {
  background-color: #d32f2f;
}


.no-approval-needed-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 1.1rem;
  color: #555;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 50%;
  text-align: center;
}

