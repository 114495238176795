@import '../../index.scss';
/* FormStyles.css */

/* Gemeinsame Styles für alle Container */
.form-container {
  /*display: block;*/
  /*box-sizing: border-box;*/
  gap: 20px;
  /*width: 960px;*/
  max-width: 100%;
  /* mehr Platz für das Formular */
  margin-top: auto;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 25px;
  padding-bottom: 25px;
  /*border: 1px solid #ddd;
  border-radius: 10px;
  align-items: center;*/
  /*background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Fügt eine glatte Übergangsanimation hinzu */
}

.form-container:hover {
  /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.10), 0 3px 4px rgba(0, 0, 0, 0.06);*/
}

.form-container form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Styles für Formular-Container */
.form-container h3 {
  text-align: left;
  font-size: 1.5em;
}

.form-container body {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.form-container label {
  display: flex;
  flex-direction: column;
  /* Beschriftungen erscheinen jetzt über den Eingabefeldern */
  margin-top: 20px;
  margin-bottom: 5px;
}

.form-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 12px;
}

.form-container button:first-of-type:disabled {
  background-color: $color-dark-gray;
  color: white;
  cursor: not-allowed;
}

.form-container button:last-of-type:disabled {
  background-color: $color-dark-gray;
  color: white;
  cursor: not-allowed;
}


/* Styles für Buttons in Formular-Container */
.button-container {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 30px;
  border-top: 1px solid #E5E5E5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .e-button {
    margin: 0 12px;
  }
}

.form-container button:first-of-type {
  background-color: #777;
  color: white;
}

.form-container button:first-of-type:hover {
  background-color: #555;
}

.form-container button:last-of-type {
  background-color: #ff0000;
  color: white;
}

.form-container button:last-of-type:hover {
  background-color: #ff0000;
}

.form-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 40px;
}

.form-container table :nth-child(odd) {
  background-color: #f7f7f7;
}

.form-container table :nth-child(even) {
  background-color: #fff;
}



.form-container table tr:hover {
  outline: 1.7px solid #eaeaea;
  /* Schwarze Umrandung als Beispiel */
}





.text-input-container input,
.form-container select {
  //width: 100%;
  font-size: 14px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0 0 20px 0;
  /* Abgerundete Ecken */
}


.form-container .radio-button-div {
  display: flex;
  align-items: center;
}


.form-container .greyBox {
  background-color: #f7f7f7;
  padding: 15px 20px;
  margin-bottom: 20px;
}

.form-container a {
  color: #ff0000;
  text-decoration: underline;
  letter-spacing: 0.05em;
}

.form-container .hidden {
  display: none;
}

.checkbox-container {
  margin-bottom: 20px;
  /* Abstand zwischen den Checkbox-Gruppen */
}

.checkbox-div {
  display: flex;
  align-items: center;
  /* Zentriert das Label mit der Checkbox */
  margin-bottom: 10px;
  /* Abstand zwischen den einzelnen Checkboxen */
}

.checkbox-div input[type="checkbox"] {
  margin-right: 10px;
  /* Abstand zwischen Checkbox und Label */
}

.checkbox-div label {
  margin: 0;
  /* Entfernt den Standardabstand */
  display: block;
  /* Erlaubt die Kontrolle über Breite und Höhe */
}

/* Optional: Stil für die Überschrift anpassen */
.checkbox-header {
  margin-bottom: 15px;
  /* Abstand zwischen Überschrift und erster Checkbox */
}