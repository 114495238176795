@import url('https://dev2.videocreator.net/media/css/css2.css');


$color-bg-gray: #F3F4F6;
$color-dark-gray: rgba(102, 102, 102, 1);
$color-table-font: rgba(107, 114, 128, 1);
$color-red-dark: rgb(157, 0, 0);
$color-border-gray: #E5E5E5;


* {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: 'Open Sans', 'Lato', sans-serif;
  margin: 0;
  font-size: 14px;
  background: $color-bg-gray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato', sans-serif;
  line-height: 1.2;
  margin: 0;
  font-weight: 900;
  margin-bottom: .5em;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
  color: red;
}

p {
  line-height: 1.7;
  margin-bottom: 1.5em;
}

a,
a:visited,
a:active,
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

li {
  padding: 3px;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 750px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 1002px) {
  .container {
    max-width: 972px;
  }
}

@media (min-width: 1278px) {
  .container {
    max-width: 1224px;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1440px;
  }
}

.page-title {
  background: white;
  height: 86px;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

  * {
    margin-bottom: 0;
  }
}

img {
  max-width: 100%;
}


@import "components/Campaigns/Campaigns";
@import "components/Downloads/Downloads";
@import "components/Layout/Header";